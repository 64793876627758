
/**
 * Call and set Instagram feed using InstFeed.js plugin
 */
class InstaFeedPlugin {

    /**
     * Set feed for widget in footer.
     */
    static footer() {
        const transportFeedLoadMoreBtn = document.getElementById('js-ig-feed-more'),
            transportFeedContainer = document.getElementById('js-ig-feed');

        if (transportFeedContainer) {
            const transportFeed = new Instafeed({
                target: 'js-ig-feed',
                get: 'user',
                userId: '8241051629',
                accessToken: '8241051629.1677ed0.ef8efa79b154402da38a12b4815172c6',
                limit: 9,
                template: '<a class="ig-feed__item" href="{{link}}" target="_blank"><img class="ig-feed__img" src="{{image}}" alt="{{caption}}" /></a>',
                resolution: 'standard_resolution',
                after: function () {
                    // remove "load more" button if there are not next posts
                    if (!this.hasNext() && transportFeedLoadMoreBtn) {
                        transportFeedLoadMoreBtn.remove();
                    }
                }
            });

            // init plugin
            transportFeed.run();

            // set "load more" btn
            if (transportFeedLoadMoreBtn) {
                transportFeedLoadMoreBtn.addEventListener('click', function () {
                    transportFeed.next();
                });
            }
        }
    }
}
