
"use strict";

/**
 * Detect if iimage format WEBP is supported
 * @returns {Promise<boolean|Promise<boolean>>}
 */
async function supportsWebp() {
    if (!self.createImageBitmap) return false;
    const webpData = 'data:image/webp;base64,UklGRh4AAABXRUJQVlA4TBEAAAAvAAAAAAfQ//73v/+BiOh/AAA=';
    const blob = await fetch(webpData).then(r => r.blob());
    return createImageBitmap(blob).then(() => true, () => false);
}

(async () => {

    if(await supportsWebp()) {
        // console.log('webps is supported');
    }
    else {
        document.body.classList.add('no-webp');
        // console.log('webps is not supported');
        // get all images with webp format
        const allWebpImgs = document.getElementsByClassName('js-webp-image');

        // if webp images exist
        if (allWebpImgs.length > 0) {
            for (let i = 0; i < allWebpImgs.length; i++) {
                // replace source attribute by custom data-src value containing data-src attribute
                allWebpImgs[i].src = allWebpImgs[i].getAttribute('data-src');
            }
        }
    }
})();

