
class Carousel3DPlugin {

    static init() {
        let carousel = document.querySelector('.carousel');
        let cellCount = 6;
        if ( carousel.classList.contains('carousel--5') ) cellCount = 5;
        let selectedIndex = 0;

        function rotateCarousel() {
            let angle = selectedIndex / cellCount * -360;
            carousel.style.transform = 'translateZ(-288px) rotateY(' + angle + 'deg)';
        }

        let prevButton = document.querySelector('.carousel-previous-button');

        if (prevButton && carousel) {
            prevButton.addEventListener('click', function () {
                selectedIndex--;
                rotateCarousel();
            });
        }

        let nextButton = document.querySelector('.carousel-next-button');

        if (nextButton && carousel) {
            nextButton.addEventListener('click', function () {
                selectedIndex++;
                rotateCarousel();
            });
        }
    }
}

// count translateZ if you change size
let tz = Math.round((190 / 2) / Math.tan(Math.PI / 5));